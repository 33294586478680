import {EventEmitter} from "events";

export default abstract class AbstractTrigger extends EventEmitter {

    protected eventEmitter: EventEmitter;
    protected fired: number = 0;
    protected repeat: number;
    protected options: any;

    // determine when the trigger is firing
    // this function should be overridden
    private isArmed: Boolean = false;

    protected abstract trigger(): Promise<void>;

    private armedTrigger: Promise<void> | null;

    constructor(eventEmitter: EventEmitter, options: any) {
        super();
        this.eventEmitter = eventEmitter;
        this.repeat = options.repeat || 1;
        this.options = options;
    }

    public arm() {
        this.isArmed = true;
        this.armedTrigger = null;
        this.armedTrigger = this.trigger();
        this.armedTrigger.then(() => {
            if (!this.isArmed) {
                return;
            }

            this.fire();
            this.fired++;
            if (this.repeat == 0 || this.repeat > this.fired) {
                this.arm();
            }
        }).catch(e => console.error(`could not fire armed trigger "${this.constructor.name}"`, e));
    }

    public disarm() {
        // todo: for canceling a promise we need to use bluebird or dom.abort.. not needed right now.
        // im just gonna ignore the result and not fire.

        this.isArmed = false;
    }

    protected fire(): void {
        this.emit("fired");
    }
}
