import AbstractStore, {Unsubscribe} from "../services/AbstractStore";

type Constructor<T> = new(...args: any[]) => T;

/**
 By using this `CustomElement` interface instead of `HTMLElement`, we avoid
 having the generated typings include most DOM API already provided by
 TypeScript. This is particularly useful since different versions of
 TypeScript may have different DOM API typings (e.g. TS 3.0.3 and TS 3.1.1).
 The required `isConnected` property is included to avoid the following
 TypeScript error:
 Type 'HTMLElement' has no properties in common with type 'CustomElement'.
 */
interface CustomElement {
    connectedCallback?(): void;
    disconnectedCallback?(): void;
    readonly isConnected: boolean;
}

export const connect = (store: AbstractStore) =>
        <T extends Constructor<CustomElement>>(baseElement: T) =>
            class extends baseElement {
                _storeUnsubscribe!: Unsubscribe;

                connectedCallback() {
                    if (super.connectedCallback) {
                        super.connectedCallback();
                    }

                    this._storeUnsubscribe = store.subscribe(() => this.stateChanged(store.state));
                    this.stateChanged(store.state);
                }

                disconnectedCallback() {
                    this._storeUnsubscribe();

                    if (super.disconnectedCallback) {
                        super.disconnectedCallback();
                    }
                }

                /**
                 * The `stateChanged(state)` method will be called when the state is updated.
                 */
                stateChanged(_state: any) {
                    // do nothing
                }
            };