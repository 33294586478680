export const LINK_TARGETS = {
    inline: "_self",
    newTab: "_blank"
};

// todo: use instead the native new URL() instead
export function paramsToQueryString(params: Record<string, string>) {
    // @ts-ignore
    return `?${Object.keys(params).map(key => key + '=' + getParamValue(params[key])).join('&')}`;
}

function getParamValue(val: string[][] | Record<string, string> | string | URLSearchParams) {
    return (typeof val !== "string") ? JSON.stringify(val) : val;
}

export function queryToParamsObject(query: string[][] | Record<string, string> | string | URLSearchParams): Record<string, string> {
    const result: Record<string, string> = {};

    const params: URLSearchParams = new URLSearchParams(query);
    const entries = params.entries();

    for(const [key, value] of entries) {
        result[key] = value;
    }

    return result;
}

export function withScheme(url: string) {
    return !/^(http|https)?:\/\//i.test(url) ? `https://${url}` : url;
}

export function removeBoardSlugFromURL(url: string) {
    const slug = window["FollozeState"].initialState.board.slug;
    let result = url?.replace(`${slug}/`, "");
    result = result?.replace(`/${slug}?`, "?");

    const regex = new RegExp(`/${slug}$`, "i");
    result = result?.replace(regex, "");
    
    // result = result?.replace(`/${slug}`, "");
    return result;
}

export function setPageMetadata({title}: {title: string}): void {
    const companyName = window["FollozeState"].initialState.company_name;
    document.title = `${title} | ${companyName}`;
}