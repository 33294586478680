// @ts-nocheck
export function enrich(apiKey, onEnrichment) {
    function appendScripts() {
      const sc1 = document.createElement('script');
      sc1.type = 'text/javascript';
      sc1.async = true;
      sc1.defer = true;
      sc1.src = `//${apiKey}.d41.co/sync/`;

      const sc2 = document.createElement('script');
      sc2.type = 'text/javascript';
      sc2.src = '//cdn-0.d41.co/tags/dnb_coretag_v5.min.js';

      const body = document.getElementsByTagName('body')[0];
      body.appendChild(sc1);
      body.appendChild(sc2);
    }

    function getDnbData() {
        // @ts-ignore
        if(typeof(dnbvid) === "undefined") {
            setTimeout(getDnbData, 50);
        }
        else {
            const startTime = new Date().getTime();
            // @ts-ignore
            dnbvid.getData(apiKey, "json", "T", {}, function(dnb_Data){
                if (dnb_Data.hasOwnProperty('duns')) {
                    const requestTime = new Date().getTime() - startTime;
                    onEnrichment({duns: dnb_Data.duns}, requestTime);
                }
            });
        }
    }

    appendScripts();
    getDnbData();
}
