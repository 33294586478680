import { CookieMatchingConfig } from "client-sdk";
import { CookieMatchMethod, CookieMatchResult } from './CookieMatchingService';

const MAX_RETRIES = 4;
const INTERVAL_TIME = 1 * 1000;

export const cookieMatch: CookieMatchMethod = (config: CookieMatchingConfig) => {
  return new Promise((resolve)=>{
    let request: Promise<CookieMatchResult>;
    if(config.load_local_script){
      request = loadCookieMunchkinScript(config.api_key).then(()=>{
        return getMarketoCookieMatching();
      });
    } else {
      request = getMarketoCookieMatching();
    }
    request.then((value)=>{
      resolve(value);
    });
  });
};

function loadCookieMunchkinScript(apiKey: string) {
  return new Promise((resolve)=>{
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = '//munchkin.marketo.net/munchkin.js';
    s.onload = function(){
      window['Munchkin'].init(apiKey);
      resolve(null);
    };
    document.getElementsByTagName('head')[0]?.appendChild(s);
  });
}

function getMarketoCookieMatching(): Promise<CookieMatchResult> {
  return new Promise((resolve)=>{
    let iterations = 0;
    let intervalId = setInterval(function(){
      let cookie_value = getMarketoCookie();
      const isSuccess = cookie_value && cookie_value.length > 0;
      if(iterations >= MAX_RETRIES || isSuccess){
        const data: CookieMatchResult = {
          value: cookie_value,
          isSuccess: !!isSuccess
        };
        clearInterval(intervalId);
        resolve(data);
      }
      iterations ++;
    }, INTERVAL_TIME);
  });
}

function getMarketoCookie(){
  const cookiename = '_mkto_trk=';
  const cookieValues = document.cookie.split(';');
  for (let cookieValue of cookieValues) {
      while (cookieValue.charAt(0)==' '){
        cookieValue = cookieValue.substring(1, cookieValue.length);
      }
      if (cookieValue.indexOf(cookiename) == 0){
        return cookieValue.substring(cookiename.length, cookieValue.length);
      }
  }
  return null;
}