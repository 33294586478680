import {LiveBoardController} from "../controllers/LiveBoardController";
import {State, Store} from "./Store";
import {type AnalyticEventPrepared, ClientSDK, FlzEvent} from "client-sdk";


export default class AnalyticsService {
    private static BEACON_INTERVAL = 10 * 1000; // 30 seconds
    private boardController: LiveBoardController;
    private sdk: ClientSDK;
    private store: Store;

    private eventsBatch: AnalyticEventPrepared[] = [];

    private isTabHidden = false;

    constructor(boardController: LiveBoardController, sdk: ClientSDK, store: Store) {
        this.boardController = boardController;
        this.sdk = sdk;
        this.store = store;
        this.isTabHidden = (store.state as State).isTabHidden;
        this.startTransmissionLoop();

        this.store.subscribe((state: State) => {
            if (state.isTabHidden != this.isTabHidden) {
                this.isTabHidden = state.isTabHidden;
                if (this.isTabHidden) {
                    this.transmit();
                }
            }
        });
    }

    private startTransmissionLoop() {
        setInterval(() => {
            this.transmit();
        }, AnalyticsService.BEACON_INTERVAL);
    }

    public addToQueue(e: FlzEvent) {
        const {type, payload, isCustom}: {type: string, payload: unknown, isCustom: boolean} = e.payload;

        if (typeof type !== "string" || (isCustom !== true && isCustom !== false)) {
            console.error("can't add event to analytic queue - illegal structure", e.payload);
            return;
        }

        const datetime = new Date();
        const analyticEvent: AnalyticEventPrepared = {
            type: type,
            session_guid: (this.store.state as State).session_guid,
            _timestamp: Math.round(datetime.getTime() / 1000),
            timestamp_delta: 0,
            timezone_offset: datetime.getTimezoneOffset(),
            timezone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
            is_custom: isCustom,
            metadata: payload,
        };
        this.eventsBatch.push(analyticEvent);

        if (this.isTabHidden) {
            this.transmit();
        }
    }

    /**
     * sends all added events
     */
    transmit() {
        if (this.eventsBatch.length === 0) {
            return;
        }

        // calculate deltas
        const now = Math.round((new Date()).getTime() / 1000);
        this.eventsBatch = this.eventsBatch.map(e => {
            e.timestamp_delta = e._timestamp! - now;
            // delete e._timestamp;
            return e;
        });

        try {
            console.debug("beacon", this.eventsBatch);
            this.sdk.analytics.sendBeacon(this.eventsBatch);
            this.eventsBatch = [];
        } catch (err) {
            console.warn("could not send analytic beacon", err);
        }
    }

}
