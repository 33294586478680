
export type methodDescriptor = {methodName: string, title?: string, desc?: string, icon?: string};

export abstract class AbstractController {

    static methodList: Record<string, methodDescriptor[]> = {};

    getAllMethods(): methodDescriptor[] {
        // @ts-ignore
        return this.constructor.methodList[this.constructor.name];
    }
}