import { cookieMatch as marketoCookieMatch } from './Marketo';
import { ClientSDK, CookieMatchingConfig } from "client-sdk";
import get from 'lodash/get';

type Providers = {
  [key: string]: {
    cookieMatchMethod: CookieMatchMethod;
  }
}
export type CookieMatchResult = {
  value: any;
  isSuccess: boolean;
};
export type CookieMatchMethod = (config: CookieMatchingConfig) => Promise<CookieMatchResult>;

const PROVIDERS: Providers =  {
  marketo: {
    cookieMatchMethod: marketoCookieMatch
  }
};

export default function cookieMatching(sdk: ClientSDK, config?: CookieMatchingConfig) {
  if(config == null){
    return;
  }
  const { type, run_cookie_matching, load_local_script } = config;
  const cookieMatchMethod: CookieMatchMethod | undefined = get(PROVIDERS[type], 'cookieMatchMethod');
  if (!cookieMatchMethod) {
    return;
  }

  cookieMatchMethod(config).then((result)=>{
    if(run_cookie_matching) {
      sdk.liveboard.updateCookieMatching(type, result.value, result.isSuccess);
    }
  });
}

