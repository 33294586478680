import {PingPayload} from "client-sdk";
import {AnalyticsController} from "../controllers/AnalyticsController";

const PING_INTERVAL =  window["FollozeState"].envConfig?.pingInterval || 5 * 1000; // 5 Second
const NUM_PINGS_TO_DELAY = 1;

export default class PingsService {
    private controller: AnalyticsController;
    private readonly boardId: number;
    private itemId: number | null;
    private contentItemId: number | null;
    private intervalFactor: number = NUM_PINGS_TO_DELAY + 1;
    private itemViewGuid: string | null;
    private pingTimeout: NodeJS.Timeout;
    private isActive: boolean = true;

    constructor(controller: AnalyticsController, boardId: number) {
        this.controller = controller;
        this.boardId = boardId;
        this.sendPing();
    }

    onItemChanged(itemId: number | null, contentItemId: number | null, itemViewGuid: string | null) {
        this.itemId = itemId;
        this.contentItemId = contentItemId;
        this.itemViewGuid = itemViewGuid;
        this.sendPing();
    }

    onSessionStateChanged(isActive: boolean) {
        this.isActive = isActive;
        if (this.isActive) {
            this.sendPing();
        }
    }

    sendPing() {
        if (!this.isActive) {
            return;
        }

        clearTimeout(this.pingTimeout);

        // TODO - consider backoff mechanism
        this.controller.sendPing({
            leadId: this.controller.getLeadId(),
            boardId: this.boardId,
            itemId: this.itemId,
            contentItemId: this.contentItemId,
            guid: this.itemViewGuid,
            analyticsData: this.controller.getLastIdleCheck()
        } as PingPayload);

        this.pingTimeout = setTimeout(() => {
            if (this.intervalFactor > NUM_PINGS_TO_DELAY) {
                this.intervalFactor = this.intervalFactor - 1;
            }
            this.sendPing();
        },  this.intervalFactor * PING_INTERVAL);
    }
}
