import { WidgetConfig, LiveWidget, SectionConfig } from "client-sdk";
import {LiveBoard} from "../components/LiveBoard";
import {LitElement} from "lit";
import minBy from "lodash/minBy";

export default class HeaderLoadListener {
    private headerWidgetId: string;
    private widgetLoadedPromiseResolver: CallableFunction;
    private resolved: boolean = false;

    public headerLoadedPromise: Promise<LiveWidget>;

    constructor() {
        this.headerLoadedPromise = new Promise<LiveWidget>(resolver =>
            this.widgetLoadedPromiseResolver = resolver
        );
    }

    start(board: LiveBoard) {
        this.findHeaderWidgetId(board);

        if (window["fz-external-header"]) {
            this.resolve(window["fz-external-header"]);
        } else if (!this.headerWidgetId) {
            this.resolve();
        } else {
            this.checkIfHeaderLoaded(board.getWidgetEl((this.headerWidgetId)));
        }
    }

    checkIfHeaderLoaded(widget?: LitElement) {
        if (!this.headerWidgetId || this.resolved || !widget) {
            return;
        }

        if (widget.id == this.headerWidgetId) {
            widget.updateComplete.then(() => this.resolve(widget));
        }
    }

    private getHeaderSection(board: LiveBoard): SectionConfig | undefined {
        return board.sections.find(section => section.type == "header");
    }

    private getFirstHeaderWidget(board: LiveBoard, headerSection: SectionConfig): WidgetConfig | undefined {
        const headerWidgetConfigs = board.widgets.filter(widgetConfig => widgetConfig.sectionId == headerSection.id);
        return minBy(headerWidgetConfigs, (widgetConfig: WidgetConfig) => widgetConfig.position.rowEnd);
    }

    private findHeaderWidgetId(board: LiveBoard): void {
        const headerSection = this.getHeaderSection(board);
        if (headerSection) {
            const firstHeaderWidget = this.getFirstHeaderWidget(board, headerSection);
            if (firstHeaderWidget) {
                this.headerWidgetId = firstHeaderWidget.id;
            }
        }
    }

    private resolve(widget?: LitElement) {
        this.widgetLoadedPromiseResolver(widget);
        this.resolved = true;
    }
}