import { v4 as uuidv4 } from "uuid";

const stateGUID: Record<string, string> = {};

function createGuid(key: string): string {
    stateGUID[key] = uuidv4();
    return stateGUID[key] as string;
}

export default {
    create: createGuid,
    get: function(key: string): string {
        return stateGUID[key] as string;
    },
    createRandomGuid: uuidv4,
    keys: {
        'timeSpent': 'time-spent'
    }
};