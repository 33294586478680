// @ts-nocheck
const ProviderScripts = {
    ga: {
        register: function(obj){
            const id = obj.code;

            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                // @ts-ignore
                // eslint-disable-next-line prefer-rest-params
                (i[r].q=i[r].q||[]).push(arguments);},i[r].l=1*new Date();a=s.createElement(o),
                m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m);
            })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

            window["ga"]('create', id, 'auto');

            return function track(page){
                const url = new URL(page);
                window["ga"]('send', 'pageview', url.pathname);
            };
        }
    },
    munchkin: {
        register: function(obj){
            const id = obj.code;
            const pending = [];
            let didInit;

            (function() {
                function initMunchkin() {
                    if(!didInit) {
                        didInit = true;
                        window["Munchkin"].init(id);

                        while(pending.length)
                            track(pending.shift());
                    }
                }
                const s = document.createElement('script');
                s.type = 'text/javascript';
                s.src = '//munchkin.marketo.net/munchkin.js';
                //@ts-ignore
                s.onreadystatechange = function() {
                    if (this.readyState == 'complete' || this.readyState == 'loaded') {
                        initMunchkin();
                    }
                };
                s.onload = initMunchkin;
                document.getElementsByTagName('head')[0].appendChild(s);
            })();

            function track(page){
                if(window["Munchkin"] && typeof(window["Munchkin"].munchkinFunction) == "function") {
                    window["Munchkin"].munchkinFunction("visitWebPage", {url: page});
                }
                else {
                    pending.push(page);
                }
            }

            return track;
        }
    },
    eloqua: {
        register: function(obj){
            const id = obj.code;
            window["_elqQ"] = window["_elqQ"] || [];
            window["_elqQ"].push(['elqSetSiteId', id]);
            window["_elqQ"].push(['elqTrackPageView']);

            (function() {
                const s = document.createElement('script'); s.type = 'text/javascript';
                s.async = true; s.src = '//img.en25.com/i/elqCfg.min.js';
                const x = document.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            })();

            return function track(page){
                window["_elqQ"].push(['elqTrackPageView', page]);
            };
        }
    },
    pardot: {
        register: function(obj){
            window["piAId"] = obj.pi_aid;
            window["piCId"] = obj.pi_cid;
            window["piHostname"] = obj.pi_hostname;

            (function() {

                function async_load(){
                    const s = document.createElement('script');
                    s.type = 'text/javascript';
                    s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
                    const c = document.getElementsByTagName('script')[0];
                    c.parentNode.insertBefore(s, c);
                }

                async_load();
            })();
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return function track(page) {};
        }
    }
};

export default class CampaignAnalytics {
    private trackings: any[];
    private isPreview: boolean;

    constructor(isPreview: boolean) {
        this.trackings = [];
        this.isPreview = isPreview;
    }

    register(analyticsSettings) {
        if (this.isPreview) {
            return;
        }

        for(const provider in analyticsSettings) {
            if(!analyticsSettings[provider] || !analyticsSettings[provider].active) {
                continue;
            }

            const track = ProviderScripts[provider].register(
                analyticsSettings[provider]
            );

            this.trackings.push(track);
        }
    }

    track(page) {
        for(let i = 0; i < this.trackings.length; i++) {
            console.log("track", this.trackings[i], page);
            this.trackings[i](page);
        }
    }
}