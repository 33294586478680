// @ts-nocheck
export default class VisibilityService {
    private readonly hiddenAttr: string;
    private visibilityChangeAttr: string;
    private changeCallbacks: CallbackFunction[] = [];

    constructor() {
        if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
            this.hiddenAttr = "hidden";
            this.visibilityChangeAttr = "visibilitychange";
        } else if (typeof document['mozHidden'] !== "undefined") {
            this.hiddenAttr = "mozHidden";
            this.visibilityChangeAttr = "mozvisibilitychange";
        } else if (typeof document['msHidden'] !== "undefined") {
            this.hiddenAttr = "msHidden";
            this.visibilityChangeAttr = "msvisibilitychange";
        } else if (typeof document['webkitHidden'] !== "undefined") {
            this.hiddenAttr = "webkitHidden";
            this.visibilityChangeAttr = "webkitvisibilitychange";
        }

        document.addEventListener(this.visibilityChangeAttr, this.fireChange);
        window.addEventListener("beforeunload", () => this.destroy());
    }

    destroy() {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.changeHandler = () => {};
        document.removeEventListener(this.visibilityChangeAttr, this.fireChange);
    }


    onChange(handler) {
        this.changeCallbacks.push(handler);
    }

    isHidden() {
        return this.hiddenAttr ? document[this.hiddenAttr] : false;
    }

    fireChange = () => {
        const isHidden = this.isHidden();
        this.changeCallbacks.forEach(cb => cb(isHidden));
    };
}
