import { getCookie, setCookie } from "../helpers/Helpers";
import { LiveBoardController } from "../controllers/LiveBoardController";
import { FlzEvent, GeoLocationResponseV1, CookieConsentWidget } from "client-sdk";
import get from "lodash/get";

export default class CookiesConsentService {
    PRIVACY_COOKIE_NAME = 'privacywarning';
    COOKIE_MANAGEMENT_TYPES = {
        internal: "internal",
        external: "external"
    };
    REGULATED_COUNTRIES_CODES = [
      'AT',
      'BE',
      'BG',
      'HR',
      'CZ',
      'CY',
      'DK',
      'EE',
      'FI',
      'FR',
      'DE',
      'EL',
      'HU',
      'IE',
      'IT',
      'LV',
      'LT',
      'LU',
      'MT',
      'NL',
      'PL',
      'PT',
      'SK',
      'ES',
      'SE',
      'GB',
      'UK',
      'GR',
      'EU'
    ];

    geoLocation: GeoLocationResponseV1;
    privacySettings: any;
    organizationId: number;
    controller: LiveBoardController;
    trackCurrentLead: boolean = true;
    cookieManagement: any;
    internalCookieManagement: boolean;
    externalCookieManagement: boolean;
    cookiesConsentWidget: CookieConsentWidget;
    isRegulatedCountry: boolean;
    private consentListeners: Function[] = [];

    constructor(privacySettings: any, organizationId: number, isRegulatedCountry: boolean, controller: LiveBoardController) {
        this.privacySettings = privacySettings;
        this.organizationId = organizationId;
        this.isRegulatedCountry = isRegulatedCountry;
        this.controller = controller;
        this.cookieManagement = get(this.privacySettings, 'cookie_management', null);
        this.internalCookieManagement = this.cookieManagement == this.COOKIE_MANAGEMENT_TYPES.internal;
        this.externalCookieManagement = this.cookieManagement == this.COOKIE_MANAGEMENT_TYPES.external;
    }

    initializeCookiesConsent = (cookiesConsentWidget: CookieConsentWidget) => {
        this.cookiesConsentWidget = cookiesConsentWidget;
        this.controller.getGeoLocationInfo().then(geoLocation => {
            this.geoLocation = geoLocation;

            if (this.isConsentNeeded()) {
                this.internalCookieManagement && this.cookiesConsentWidget.show();
            } 

            if (this.internalCookieManagement && !this.hasConsentAlready() && !this.isExplicitConcentNeeded()){
                this.createServerSessionCookies();
                this.consentGiven();
                cookiesConsentWidget == null && this.setConsentCookie();
            } else if (!this.isConsentNeeded() || this.hasConsentAlready()) {
                this.consentGiven();
            }
        });
    };

    showCookiesConsent(widget?: CookieConsentWidget) {
        if (widget?.isCookieConsentable) {
            widget.show();
        } else {
            this.internalCookieManagement && this.cookiesConsentWidget?.show();
        }
    }

    private isConsentNeeded(): boolean {
        const countryCode = get(this.geoLocation,'country_code', '');

        if(!this.cookiesConsentWidget) return false;

        if (this.hasConsentAlready()) {
            return false;
        }
        else if (this.isExplicitConcentNeeded()) {
            return true;
        }
        else {
            return this.isConsentNeededByCountry(countryCode, this.cookiesConsentWidget.getOnlyRegulatedCountriesValue());
        }
    }

    private isConsentNeededByCountry(countryCode: string, isRegulatedCountries: boolean): boolean {
        if (isRegulatedCountries && countryCode) {
            return this.REGULATED_COUNTRIES_CODES.includes(countryCode.toUpperCase());
        }
        else {
            return true;
        }
    }

    hasConsentAlready(): boolean {
        const consentCookie = getCookie(this.PRIVACY_COOKIE_NAME);
        const cookieData = get(consentCookie, [this.organizationId], null);

        if (cookieData) {
            return cookieData.hasConsent;
        }
        return false;
    }

    stopTrackingForSession() {
        this.trackCurrentLead = false;
    }

    createServerSessionCookies(consentOrigin?: string): Promise<number | void>  {
        const consentGiven = consentOrigin == 'Folloze.consentGiven';
        const externalCookieManagementAfterConsent = this.externalCookieManagement && consentGiven;

        if (!this.cookieManagement || this.internalCookieManagement || externalCookieManagementAfterConsent) {
            return this.controller.setLeadSessionCookie();
        } else {
            return Promise.resolve();
        }
    }

    setConsentCookie() {
        const privacyWarningCookie = getCookie(this.PRIVACY_COOKIE_NAME) || {};
        privacyWarningCookie[this.organizationId] = { hasConsent: true };
        setCookie(this.PRIVACY_COOKIE_NAME, privacyWarningCookie, this.currentDomain());
    }

    private currentDomain(): string {
        let hostname = window.location.hostname;
        if(hostname.indexOf('www.') === 0) {
            hostname = hostname.slice(3);
        }
       return hostname;
    }

    setLeadCookieWithConsent(e: FlzEvent) {
      this.createServerSessionCookies(e && e.payload.consentOrigin).then(leadId => {
          if (!leadId) {
              return Promise.resolve();
          }

          return this.controller.setLeadCookiesConsent(e, leadId);
      }).then(()=>{
            this.consentGiven();
      });
    }

    private consentGiven() {
        this.consentListeners.forEach(callback => callback());
        this.consentListeners = [];
    }

    setCookiesConsent(e: FlzEvent) {
      if (!this.hasConsentAlready() && this.trackCurrentLead) {
          this.setConsentCookie();
          this.setLeadCookieWithConsent(e);
      }
    }

    isExplicitConcentNeeded(): boolean {
        return this.cookiesConsentWidget?.isExplicitConsent(this.isRegulatedCountry);
    }

    isHiddenLead(): boolean {
        return this.cookiesConsentWidget?.isLeadHidden(this.isRegulatedCountry);
    }

    onConsentGiven(callback: Function) {
        if(!this.cookiesConsentWidget){
            this.consentListeners.push(callback);
        } else if (!this.isConsentNeeded() || this.hasConsentAlready()) {
            callback();
        } else {
            this.consentListeners.push(callback);
        }
    }
}