
/**
 * Function to remove listener added by `AbstractStore.subscribe()`.
 */
export interface Unsubscribe {
    (): void
}

export default abstract class AbstractStore {

    protected abstract _state: Object;

    private _cbArr: Function[] = [];
    private timeOut: NodeJS.Timeout;

    set state(data: any) {
        if (this._state === data) {
            return;
        }
        this.beforeSetState(data);
        this._state = data;

        // put the publish changes after all the event loop is cleared
        // this way there is no update every single change in a loop.
        clearTimeout(this.timeOut);
        this.timeOut = setTimeout(() => {
            this.publishChange();
        });
    }

    get state() {
        return this._state;
    }

    protected beforeSetState(data: unknown) {
        // this will run every time before set state - you can override it on store
        return;
    }

    subscribe(func: CallableFunction): Unsubscribe {
        this._cbArr.push(func);
        return this.unsubscribe(func);
    }

    unsubscribe(func: object): Unsubscribe {
        return () => {
            const index = this._cbArr.findIndex(x => x === func);
            if (index > -1) {
                this._cbArr.splice(index, 1);
            }
        };
    }

    publishChange() {
        for (const cb of this._cbArr) {
            cb(this._state);
        }
    }
}
