import {EventEmitter} from "events";
import AbstractTrigger from "./AbstractTrigger";


export class OnEventTrigger extends AbstractTrigger {
    private readonly eventName: string;

    constructor(eventEmitter: EventEmitter, options: { repeat: number, eventName: string }) {
        super(eventEmitter, options);
        this.eventName = options.eventName;
    }

    protected async trigger(): Promise<void> {
        return new Promise(resolve => {
            this.eventEmitter.on(this.eventName, () => {
                resolve();
            });
        });
    }
}
