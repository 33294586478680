import Navigo, {Match, NavigateOptions} from "navigo";
import {App} from "./components/App";
import {LiveBoardController} from "./controllers/LiveBoardController";
import find from "lodash/find";
import map from "lodash/map";
import {Store} from "./services/Store";
import {removeBoardSlugFromURL, setPageMetadata} from "./helpers/UrlHelper";

export enum Routes {
    NOT_FOUND,
    liveBoard,
    designer,
    poc,
}

export enum RouteParams {
    category = 'flz-category',
    item = 'flz-item',
}

export class Router {

    private router: Navigo;
    private app: App;
    public controller: LiveBoardController;
    private store: Store;
    private firstTimeLanded = true;

    constructor(app: App, store: Store) {
        this.app = app;
        this.store = store;
        // TODO - grab the baseRoute from the state?

        let baseRoute = window["FollozeState"].baseRoute;
        try {
            if (baseRoute.startsWith("http")) {
                baseRoute = new URL(baseRoute).pathname;
            } else {
                console.error("baseRoute is missing the protocol", baseRoute);
            }
        } catch (e) {
            console.error("could not extract path from baseRoute", baseRoute);
        }

        console.debug("baseRoute: ", baseRoute);
        this.router = new Navigo(baseRoute);
        this.routes();
        this.router.resolve();
        this.app.addEventListener("navTo", (e: CustomEvent) => {
            this.router.navigate(e.detail.route);
        });

        // listen for url hash changes
        window.addEventListener("hashchange", (e) => {
            this.controller.autoScrollAfterLanding();
        });
    }

    public getRouterState(): Match[] {
        return this.router.current as Match[];
    }

    public getCategorySlug() {
        return this.router.current?.[0]?.data?.categorySlug || this.router.current?.[0]?.params?.[RouteParams.category];
    }

    public getItemSlug() {
        return this.router.current?.[0]?.data?.itemSlug || this.router.current?.[0]?.params?.[RouteParams.item];
    }

    // triggers navigation inside spa
    navTo(path: string, widgetId: string | undefined, options?: NavigateOptions, keepQueryString: boolean = true) {
        let to, queryString = "";

        if (keepQueryString) {
            let urlParams = {};
            if (widgetId) {
                urlParams = {...this.router.current?.[0]?.params, fw: widgetId.slice(-5)};
            } else {
                // @ts-ignore - string can't handle that :
                if (this.router.match(path)?.[0]?.route?.name == ':boardSlug') {
                    // @ts-ignore
                    delete this.router.current[0]?.params["fw"];
                }
                // @ts-ignore
                urlParams = this.router.current[0].params;
            }

            const currentUrl = new URL(location.href);
            const url = new URL(currentUrl.protocol + currentUrl.host + path);

            // // merge the url params with the query string
            urlParams = {...urlParams, ...Object.fromEntries(url.searchParams)};

            // add search params to url
            for (const [key, value] of Object.entries(urlParams)) {
                url.searchParams.set(key, value as string);
            }

            // build a new url with the new search params
            to = url.pathname + url.search;
        } else {
            to = path;
        }

        // adding page name to url ex: ?p=registration
        // const pageName = this.app.board.getCurrentPageName();
        // if (pageName !== "default") {
        //     const toUrl = new URL(location.origin + to);
        //     const params = new URLSearchParams(toUrl.search);
        //     params.set("p", pageName);
        //     toUrl.search = params.toString();
        //     to = toUrl.pathname + toUrl.search;
        // }

        const samePath = path == `/${this.getRouterState()[0]?.url}`;
        const sameQueryString = this.getRouterState()[0]?.queryString == queryString;
        
        if (samePath && sameQueryString) {
            this.setCurrentItem(this.getRouterState()[0]!);
        } else {
            this.router.navigate(to, options);
        }

    }

    setCurrentItem(e: Match) {
        if (!this.controller) {
            return;
        }

        if (this.registrationMiddleware(e)) {
            return;
        }

        const itemViewerHistory = this.controller.getState().item_viewer.history || {};
        let itemRoute = "";
        if (!e.url) {
            // build the search only with category and item
            const params = e.params as Record<string, string>;
            const searchParams = new URLSearchParams();
            searchParams.set(RouteParams.category, decodeURI(params[RouteParams.category] || ''));
            searchParams.set(RouteParams.item, decodeURI(params[RouteParams.item] || ''));
            itemRoute = `?${searchParams.toString()}`;
        } else {
            itemRoute = `/${decodeURI(e.url)}`;
        }
        
        const currentItem = itemViewerHistory[`${itemRoute}`];

        if (currentItem) {
            this.controller.setCurrentItem(currentItem);
            setPageMetadata({title: currentItem.seo_title});
        }
    }

    setCurrentCategory(e: Match) {
        const categorySlug = e.data?.categorySlug || e.params?.[RouteParams.category];
        const widgetId = e.params?.fw || e.params?.w;

        if (this.registrationMiddleware(e)) {
            return;
        }

        if (!this.controller) {
            return;
        }

        const categoriesData = this.controller.getState()?.categories?.data || {};
        const currentCategory = find(categoriesData, category => category.slug == categorySlug);

        if (currentCategory) {
            this.controller.setCurrentCategory(currentCategory, widgetId);
            setPageMetadata({title: currentCategory.seo_title});
        }
    }

    /**
     * returns true to prevent navigation & false to allow navigation to item
     */
    registrationMiddleware(e: Match): boolean {
        if (!this.firstTimeLanded) {
            return false;
        }

        this.firstTimeLanded = false;
        const registrationPageName = "registration";
        // const urlSearchParams = new URLSearchParams(e.queryString);
        // const isRegistrationUrl = urlSearchParams.has("p") && urlSearchParams.get("p") === registrationPageName;
        // const isCurrentPageRegistration = this.app.board.getCurrentPageName() === registrationPageName;
        const isLandingPageRegistration = this.store.state.board.landing_page === registrationPageName;

        // if item is in registration then allow the navigation to it
        // if (isCurrentPageRegistration && isRegistrationUrl) {
        //     return false;
        // }

        // empty the url to just "/" (in case of item landing)
        // actual selection of registration page happens in liveBoard.ts constructor
        if (isLandingPageRegistration) {
            console.debug("router: registration navigation");
            // this.router.navigate(`/${e.data?.boardSlug}`);

            // add the params from the url
            const urlSearchParams = new URLSearchParams(e.queryString);
            this.router.navigate(`?${urlSearchParams.toString()}`);
            return true;
        }
        return false;
    }

    redoLanding() {
        const url = this.store.state.landedUrl;
        let landedUrl = url.pathname + url.search + url.hash;
        landedUrl = removeBoardSlugFromURL(landedUrl);
        this.controller.refreshPersonalization(() => {
            console.debug(`router: re-navigate to: ${landedUrl}`);
            this.router.navigate(landedUrl);
        });
        // location.href = this.store.state.landedUrl.href;
    }

    handleBoardRoute(e: Match) {
        console.debug("router: board route");
        if (this.registrationMiddleware(e)) {
            return;
        }
        this.controller?.setCurrentItem(null);
        this.app.route = Routes.liveBoard;
    }

    routes() { 
        this.registerBoardRoute();
        this.registerDesignerRoute();
        this.registerCategoryRoute();
        this.registerItemRoute();
        this.registerUnMatchedRoute();
    }

    private registerBoardRoute() {
        this.router.on('/', (e: Match) => {
            if (e.params) {
                const paramsKeys = Object.keys(e.params);
                if (paramsKeys.includes(RouteParams.item)) {
                    console.debug("router: new item route", e);
                    this.setCurrentItem(e);
                    this.app.route = Routes.liveBoard;
                    return;
                } else if (paramsKeys.includes(RouteParams.category)) {
                    console.debug("router: new category route", e);
                    this.setCurrentCategory(e);
                    this.app.route = Routes.liveBoard;
                    return;
                }
            }
            this.handleBoardRoute(e);
            setPageMetadata({title: window["FollozeState"].initialState.board.seo_title});
        });
    }

    private registerDesignerRoute() {
        this.router.on('/designer', () => this.app.route = Routes.designer);
    }

    private registerCategoryRoute() {
        this.router.on(':categorySlug', (e: Match) => {
            console.debug("router: category route", e);
            this.setCurrentCategory(e);
            this.app.route = Routes.liveBoard;
        });
    }

    private registerItemRoute() {
        this.router.on(':categorySlug/:itemSlug', (e: Match) => {
            console.debug("router: item route", e);
            this.setCurrentItem(e);
            this.app.route = Routes.liveBoard;
        });
    }

    private registerUnMatchedRoute() {
        this.router.on('*', (e: Match) => {
            this.app.route = Routes.liveBoard;
        });
    }
}
