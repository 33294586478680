import AbstractStore from "./AbstractStore";
import cloneDeep from "lodash/cloneDeep";
import {CategoriesResponseV2, type InitialState, type LeadResponseV1} from "client-sdk";

export type State =
    InitialState & {
    // define here extra state entities that dose not exists in InitialState
    categories: {data: CategoriesResponseV2["data"]};
    modal?: unknown;
    lead?: LeadResponseV1;

    // todo: fix bad collision between type ContactCardInfo & BoardSellerResponseV1
    contact_card_info: any;

    landedUrl: URL | undefined;
    isTabHidden: boolean;
}

export class Store extends AbstractStore {
    protected _state: State = {} as State;

    constructor() {
        super();
        this._state = cloneDeep(window["FollozeState"]["initialState"]) as State;
        this._state.landedUrl = new URL(location.href);
        this._state.isTabHidden = false;
    }

    // set state(data: State) {
    //     super.state = data;
    // }
    //
    // get state(): State {
    //     return super.state as State;
    // }

    setState(changes: Partial<State>) {
        super.state = { ...this._state, ...changes };
    }

    protected beforeSetState(state: State) {
        this.updateItemsStorage(state);
    }

    updateItemsStorage(state: State) {
        const item = state.item_viewer?.current;
        if (!item || !item.route) {
            return;
        }

        if (!state.item_viewer.items) {
            state.item_viewer.items = {};
        }

        const items = state.item_viewer.items;
        if (!items[item.route]) {
            items[item.route] = item;
            console.debug("update items in item_viewer items", state.item_viewer);
        }
    }
}

export default new Store();
