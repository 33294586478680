// @ts-nocheck
// todo(tanya): remove nocheck, add types here and in all providers
import { enrich as sixSenseEnrich } from './providers/sixSense';
import { enrich as dnbEnrich } from './providers/dnb';
import { enrich as demandbaseEnrich } from './providers/demandbase';
import { ClientSDK, DataEnrichmentConfig } from "client-sdk";
import get from "lodash/get";

const PROVIDERS = {
  six_sense: {
    enrichmentMethod: sixSenseEnrich
  },
  dnb: {
    enrichmentMethod: dnbEnrich
  },
  demandbase: {
    enrichmentMethod: demandbaseEnrich
  }
};

export default function enrichData(sdk: ClientSDK, config?: DataEnrichmentConfig, callback?: (enriched: boolean)=>void) {
    if (!config || !config.type) {
        return;
    }

    const enrichmentMethod = get(PROVIDERS[config.type], 'enrichmentMethod');
    if (!enrichmentMethod) {
        callback && callback(false);
        return;
    }

    const handleEnrichment = (enrichmentData, requestTime = null) => {
        sdk.liveboard.updateEnrichment(config.type, enrichmentData, requestTime).then(() => {
          callback && callback(true);
        }).catch(() => {
          callback && callback(false);
        });
    };

    enrichmentMethod(config.api_key, handleEnrichment);
}
