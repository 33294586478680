// @ts-nocheck
export function enrich(apiKey, onEnrichment) {
    const cookieXhr = new XMLHttpRequest();
    const startTime = new Date().getTime();
    const urlParams = new URLSearchParams(window.location.search);
    cookieXhr.onreadystatechange = function () {
      const cookie = cookieXhr.responseText;
      if (cookieXhr.readyState === 4) {
        const firmographicXhr = new XMLHttpRequest();
        const extraParams = [];
        if(urlParams.get('6si_xuid')) {
          extraParams.push(["xandrId", urlParams.get('6si_xuid')]);
        }
        if(urlParams.get('6si_xcpid')) {
          extraParams.push(["splitId", urlParams.get('6si_xcpid')]);
        }
        const qs = extraParams.length == 0 ? "" : '?' + extraParams.map((part) => `${part[0]}=${encodeURIComponent(part[1])}`).join('&');
        firmographicXhr.open('GET', 'https://epsilon.6sense.com/v3/company/details'+qs);
        firmographicXhr.setRequestHeader('Authorization', 'Token ' + apiKey);
        if (cookieXhr.status === 200 && cookie.match('6suuid=')) {
          const visitorCookie = cookie.split('=')[1];
          firmographicXhr.setRequestHeader('EpsilonCookie', visitorCookie);
        }
        firmographicXhr.onreadystatechange = function () {
          if (firmographicXhr.readyState === 4 && firmographicXhr.status === 200) {
            const requestDuration = new Date().getTime() - startTime;
            onEnrichment(firmographicXhr.response, requestDuration);
          }
        };
        firmographicXhr.send();
      }
    };
    cookieXhr.open('GET', 'https://c.6sc.co/', true);
    cookieXhr.withCredentials = true;
    cookieXhr.send();
  }
