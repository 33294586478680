import {
    checkJsonConditions,
    FloatingWidgetConfig,
    FloatWidgetElement,
    FlzEvent,
    LiveFloatingWidget, TriggerPersistenceData
} from "client-sdk";
import {EventEmitter} from 'events';
import BoardEventsHandlers from "./BoardEventsHandlers";
import AbstractTrigger from "../triggers/AbstractTrigger";
import {TimerTrigger} from "../triggers/TimerTrigger";
import {LeaveTrigger} from "../triggers/LeaveTrigger";
import {OnEventTrigger} from "../triggers/OnEventTrigger";
import {LiveBoard} from "../components/LiveBoard";

export const FloatWidgetsTriggerTypes = {
    none: null,
    TimerTrigger: TimerTrigger,
    LeaveTrigger: LeaveTrigger,
    OnEventTrigger: OnEventTrigger,
};

export default class FloatingWidgetManager {
    private liveBoard: LiveBoard;
    private boardEvents: BoardEventsHandlers;

    private registeredWidgets: WeakMap<FloatingWidgetConfig, boolean>;
    private triggers: AbstractTrigger[];

    constructor(liveBoard: LiveBoard, eventEmitter: BoardEventsHandlers) {
        this.liveBoard = liveBoard;
        this.boardEvents = eventEmitter;
        this.boardEvents.on("register-floating-widgets-triggers", (e: FlzEvent) => {
            if (e.payload.register) {
                this.register();
            } else {
                this.unregister();
            }
        });
        this.boardEvents.on("floating-widget-manager", (e: FlzEvent) => {
            const widget: FloatWidgetElement = e.payload.widget;
            switch (e.payload.command) {
                case "show":
                    this.show(widget.config);
                    break;
                case "close":
                case "hide":
                    this.hide(widget.config);
                    break;
                default:
                    console.warn(`no command "${e.payload.command}" found in floating widget manager`);
            }
        });
    }

    get floatingWidgets(): FloatingWidgetConfig[] {
        return this.liveBoard.floatingWidgets;
    }

    private createTriggerInstance<A extends AbstractTrigger>(c: new (eventEmitter: EventEmitter, options?: any) => A, options: any): A {
        return new c(this.boardEvents, options);
    }

    public unregister() {
        let count = 0;
        this.triggers?.map(t => {
            t.disarm();
            count++;
        });
        this.triggers = [];
        this.registeredWidgets = new WeakMap<FloatingWidgetConfig, boolean>();
        for (const fwc of this.floatingWidgets) {
            this.hide(fwc);
        }
        if (count > 0) {
            console.debug(`unregistered floating widgets triggers [${count}]`);
        }
    }

    public register() {
        this.unregister();
        let count = 0;
        for (const fwc of this.floatingWidgets) {
            this.registeredWidgets.set(fwc, false);
            if (!fwc.trigger) {
                this.show(fwc);
                continue;
            }

            if (fwc.trigger.name === "ByWidgetTrigger") {
                continue;
            }

            try {
                const triggerConstructor = FloatWidgetsTriggerTypes[fwc.trigger.name];
                // @ts-ignore
                const trigger = this.createTriggerInstance(triggerConstructor, fwc.trigger.options);
                this.triggers.push(trigger);
                trigger.on("fired", () => this.show(fwc));
                trigger.arm();
                count++;
            } catch (e) {
                console.error(`could not instantiate the trigger "${fwc.trigger.name}"`, e);
            }
        }
        console.debug(`registered floating widgets triggers [${count}]`);
    }

    public show(fwc: FloatingWidgetConfig) {
        const fwEl = this.liveBoard.getWidgetEl(fwc.id) as LiveFloatingWidget;
        if (fwEl && fwEl.classList?.contains("hidden")) {
            if (fwc.trigger?.persist) {
                const persistedData = this.getPersistedData(fwc);
                const data = Object.assign(fwc, {persisted: persistedData});
                if (!checkJsonConditions(fwc.trigger?.persist.showConditions, data)) {
                    console.debug(`skip show on floating widget: ${fwc.widgetTag}|${fwc.id}`);
                    return;
                } else {
                    if (!persistedData.fields) persistedData.fields = {};
                    persistedData.fields.showed ? persistedData.fields.showed += 1 : persistedData.fields.showed = 1;
                    this.setPersistedData(fwc, persistedData);
                }
            }
            fwEl.onShow && fwEl.onShow();
            fwEl.classList?.remove("hidden");
            this.registeredWidgets.set(fwc, true);
        }
    }

    public hide(fwc: FloatingWidgetConfig) {
        const fwEl = this.liveBoard.getWidgetEl(fwc.id) as LiveFloatingWidget;
        if (fwEl && !fwEl.classList?.contains("hidden")) {
            if (fwc.trigger?.persist) {
                const persistedData = this.getPersistedData(fwc);
                if (!persistedData.fields) persistedData.fields = {};
                persistedData.fields.closed ? persistedData.fields.closed += 1 : persistedData.fields.closed = 1;
                this.setPersistedData(fwc, persistedData);
            }

            fwEl.onClose && fwEl.onClose();
            fwEl.classList?.add("hidden");
            this.registeredWidgets.set(fwc, false);
        }
    }

    private getPersistedDataKey(fwc: FloatingWidgetConfig): string {
        return `flz-floating-persistence-${this.liveBoard.boardId}-${fwc.id.slice(0, 7)}`;
    }
    private getPersistedData(fwc: FloatingWidgetConfig): TriggerPersistenceData {
        const persisted: TriggerPersistenceData = JSON.parse(localStorage.getItem(this.getPersistedDataKey(fwc)) || "null");
        let expired = false;
        if (persisted && fwc.trigger?.persist?.expiration !== "never") {
            const now = new Date().getTime();
            // difference of time in seconds
            const diff = (now - new Date(persisted.updated).getTime()) / 1000;
            if (fwc.trigger?.persist?.expiration) {
                expired = fwc.trigger.persist.expiration < diff;
            }
        }
        if (persisted && !expired) {
            return persisted;
        }
        return JSON.parse(JSON.stringify({
            fields: fwc.trigger?.persist?.fields,
            updated: new Date()
        }));
    }
    private setPersistedData(fwc: FloatingWidgetConfig, data: any) {
        if (fwc.trigger?.persist?.expiration !== "never") {
            data.updated = new Date();
        }
        return localStorage.setItem(this.getPersistedDataKey(fwc), JSON.stringify(data));
    }
}

