// @ts-nocheck
export function enrich(apiKey, onEnrichment) {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    const startTime = new Date().getTime();
  
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const requestDuration = new Date().getTime() - startTime;
        onEnrichment(xhr.response, requestDuration);
      }
    };
    const referrer = encodeURIComponent(document.referrer);
    const page = encodeURIComponent(document.URL);
    const title = encodeURIComponent(document.title);
    const url = `https://api.company-target.com/api/v3/ip.json?auth=${apiKey}&referrer=${referrer}&page=${page}&title=${title}`;
    xhr.open('GET', url, true);
    xhr.send();
  }
