import {EventEmitter} from "events";
import AbstractTrigger from "./AbstractTrigger";


export class TimerTrigger extends AbstractTrigger {
    public time: number;

    constructor(eventEmitter: EventEmitter, options: {repeat: number, time: number}) {
        super(eventEmitter, options);
        this.time = options.time;
    }

    protected trigger(): Promise<void> {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, this.time);
        });
    }
}
