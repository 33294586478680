import {type GridArea, type FlzEvent} from "client-sdk";

type LiveWidget = any;


export function downloadFile(name: string, data: any) {
    const element = document.createElement("a");
    const file = new Blob([data],
        {type: 'text/plain;charset=utf-8'});
    element.href = URL.createObjectURL(file);
    element.download = `${name}.log`;
    document.body.appendChild(element);
    element.click();
}

export function copyTextToClipboard(text: string){
    if(!text){
        console.error('error copying to clipboard: text cannot be empty');
        return false;
    }

    let result = false;
    const input = document.createElement('textarea');
    
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();

    try {
        result = document.execCommand('copy');
    } catch (e){
        console.error('error copying to clipboard: ', e);
    }

    document.body.removeChild(input);
    return result;
}

export function isCustomElement(el: HTMLElement): boolean {
    // @ts-ignore
    return el.constructor.__proto__ !== window.HTMLElement && el.constructor.__proto__ != Element;
}

export function updateAllCustomElements(){
    //@ts-ignore
    [...document.querySelectorAll('html *')].filter(isCustomElement).map(x => x.requestUpdate());
}

export function getGridAreaArr(widget: HTMLElement): GridArea {
    const tmpGridAreaArr = widget.style.gridArea.split("/");
    return {
        rowStart: parseInt(tmpGridAreaArr[0]!),
        colStart: parseInt(tmpGridAreaArr[1]!),
        rowEnd: parseInt(tmpGridAreaArr[2]!),
        colEnd: parseInt(tmpGridAreaArr[3]!),
    };
}

export function getGridAreaStr(ga: GridArea): string {
    return `${ga.rowStart} / ${ga.colStart} / ${ga.rowEnd} / ${ga.colEnd}`;
}

export function isWidgetsAdjacent(w1: LiveWidget, w2: LiveWidget): boolean {
    return isWidgetsNextToEachOther(w1, w2) || isWidgetsOnTopEachOther(w1, w2);
}

export function isWidgetsNextToEachOther(w1: LiveWidget, w2: LiveWidget): boolean {
    const tmp =
        ((w1.gridArea.rowStart <= w2.gridArea.rowStart && w2.gridArea.rowStart <= w1.gridArea.rowEnd) ||
        (w2.gridArea.rowStart <= w1.gridArea.rowStart && w1.gridArea.rowStart <= w2.gridArea.rowEnd)) &&
        (w1.gridArea.colEnd == w2.gridArea.colStart || w2.gridArea.colEnd == w1.gridArea.colStart);
    console.log("is next to", tmp);
    return tmp;
}

export function isWidgetsOnTopEachOther(w1: LiveWidget, w2: LiveWidget): boolean {
    const tmp = ((w1.gridArea.colStart <= w2.gridArea.colStart && w2.gridArea.colStart <= w1.gridArea.colEnd) ||
        (w2.gridArea.colStart <= w1.gridArea.colStart && w1.gridArea.colStart <= w2.gridArea.colEnd)) &&
        (w1.gridArea.rowEnd == w2.gridArea.rowStart || w2.gridArea.rowEnd == w1.gridArea.rowStart);
    console.log("is ontop to", tmp);
    return tmp;
}

export function hasWidgetToTheRight(w: LiveWidget, gridPos: any) {
    // for all rows
    for (let row = w.gridArea.rowStart; row < w.gridArea.rowEnd; row++) {
        const col =  w.gridArea.colEnd + 1;
        if (gridPos[col] && gridPos[col][row]) {
            return true;
        }
    }
    return false;
}

export function hasWidgetToTheLeft(w: LiveWidget, gridPos: any) {
    // for all rows
    for (let row = w.gridArea.rowStart; row < w.gridArea.rowEnd; row++) {
        const col =  w.gridArea.colStart - 1;
        if (gridPos[col] && gridPos[col][row]) {
            return true;
        }
    }
    return false;
}

function getCookieWithPostfix(cname: string): string {
    // use the cookie post fix if available (eu differentiator)
    const postFix = window["FollozeState"]?.envConfig?.cookiePostfix || "";
    cname = `${cname}${postFix}`;
    return cname;
}

export function getCookie(cname: string) {
    cname = getCookieWithPostfix(cname);
    const reg = new RegExp(`(?:(?:^|.*;\\s*)${cname}\\s*\\=\\s*([^;]*).*$)|^.*$`);
    const cvalue = decodeURIComponent(document.cookie.replace(reg, "$1"));
    try {
        return JSON.parse(cvalue);
    }
    catch(e) {
        return cvalue;
    }
}

export function setCookie(cname: string, cvalue: unknown, domain?: string) {
    cname = getCookieWithPostfix(cname);
    if(typeof(cvalue) == "object") {
    cvalue = JSON.stringify(cvalue);
  }
  const cookieDomain = domain ? `;domain=${domain}` : '';
  document.cookie = `${encodeURIComponent(cname)}=${encodeURIComponent(cvalue as string)};path=/${cookieDomain}`;
}


export function onSuccessIfExists<T>(e: FlzEvent, result?: T) {
    if (e.onSuccess) {
        result ? e.onSuccess(result) : e.onSuccess();
    } else {
        console.warn("onSuccess is not defined for event", e.action);
    }
}

export function onErrorIfExists<T>(e: FlzEvent, err: T) {
    if (e.onError) {
        e.onError(err);
    } else {
        console.error(`onError is not defined for event ${e.action}`, err);
    }
}
