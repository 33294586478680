import AbstractTrigger from "./AbstractTrigger";


export class LeaveTrigger extends AbstractTrigger {
    protected trigger(): Promise<void> {
        return new Promise(resolve => {
            document.body.addEventListener("mouseleave", () => {
                resolve();
            }, {once: true});
        });
    }
}
